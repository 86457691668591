var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"34612e591e7d6ef771ca91f58c38d2192f54fe4e"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
// eslint-disable-next-line no-restricted-imports
import { config } from './config/config';

Sentry.init({
  dsn: config.sentry.dnsWeb,
  integrations: [new Sentry.BrowserTracing()],
  // Performance Monitoring
  tracesSampleRate: config.environment.isDev ? 1.0 : 0.2,
  // Session Replay
  replaysSessionSampleRate: config.environment.isDev ? 1.0 : 0.1,
  replaysOnErrorSampleRate: config.environment.isDev ? 1.0 : 0.2,

  attachStacktrace: true,
});
